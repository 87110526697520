/*Font Size*/
body {
  font-size: calc(10px + 1.2vmin);
}
/*Font*/
body {
  font-family: 'Calibri', 'Candara', 'Segoe', 'Segoe UI', 'Optima', 'Arial', 'sans-serif';
}
h1, h2, h3, h4, div.menu a {
  font-family: 'Arial', 'Helvetica', 'sans-serif';
}
/*Color*/
body {
  color: rgba(0, 0, 0, 0.75);
  background-color:  white;
}
h1, h2, h3, h4 {
  color: rgba(2, 110, 95, 1.0);
}
div a {
  color: rgba(10, 0, 0, 0.9);
}
div.tableBorder > div > div {
  border-color: rgba(0, 0, 0, 0.25);
}
label {
  color: rgba(255, 255, 240, 0.75);
}
input[type='text'], input[type='tel'],
input[type='email'], input[type='radio'] {
  color: rgba(0, 0, 0, 0.98);
}
textarea {
  color: rgba(0, 0, 0, 0.98);
}
input[type='submit'], button[type='button'] {
  color: rgba(0, 0, 0, 0.80);
  background-color: rgba(0, 255, 100, 0.75);

}
/*General*/
body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100vh;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  text-align: left;
}
em {
  text-decoration: none;
  font-style: normal;
  font-weight: bold;
}
a, .NavLinkClass {
  text-decoration: none;
}
.NavLinkClassActive {
  border-bottom-style: solid;
  border-bottom-width: 2px;
}
h2, h3, h4 {
  margin-block-end: 0.25em;
}
div.para {
  margin-top: 1em;
}
div.indent {
  margin-left: 1em;
}
div.center {
  margin: auto;
  width: 90%;
}
italic {
  font-style: oblique;
}
p, ul, ol {
  line-height: 1.4;
}
/*Table*/
div.table {
  display: table;
}
div.table > div {
  display: table-row;
}
div.table > div > div {
  display: table-cell;
  text-align: left;
  padding: 0;
  margin: 0;
}
div.table > div > div:first-child {
  padding-left: 0.5em;
}
div.tableBorder > div > div {
    border-width: 0px;
    border-style: none;
    border-spacing: 0px 0px;
}
div.table > div.tableHeader {
  display: table-header-group;
}
div.table > div.tableHeader > div {
  font-weight: 600;
}
/*Links*/
div.menu> div > div:first-child {
  padding-left: 0 !important;
}
span.icon {
  display: inline-block;
}
span.icon > img {
  height: 1em;
}
/*Quotes*/
q, cite {
  font-size: 0.85em;
}
cite {
  margin-left: 2em;
}
/*Lists*/
/* ul {
  list-style: none;
  padding-inline-start: 0.5em;
}
ol {
  padding-inline-start: 1.5em;
}
ul > li {
  background-image: url('/img/glyph/bullet-circle-filled.svg');
  background-repeat: no-repeat;
  background-position: 0 0.5em;
  background-size: 0.50em;
  padding-left: 1.1em;
} */
ul li, ol li {
  text-align: left;
  margin-bottom: 0.2em;
}
ul li:last-child, ol li:last-child {
  margin-bottom: 0;
}
/*Forms*/
input[type='text'], input[type='tel'],
input[type='email'], textarea {
  width: 25em;
}
label {
  display: block;
  margin-top: 0.5em;
  margin-bottom: 0.3em;
}
input[type='text'], input[type='tel'], input[type='email'] {
  height: 2em;
}
textarea {
  height: 5em;
  padding-top: 0.5em;
}
input[type='submit'], button[type='button'] {
  margin-top: 1em;
  margin-bottom: 1em;
  width: 25em;
  height: 4em;
}
input[type='text'], textarea, input[type='submit'], button[type='button'] {
  border-width:  1px;
  border-style:  solid;
  border-radius: 5px;
}
div.radio input[type='radio'],
div.radio label {
  display: inline-block
}

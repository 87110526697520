body {
  color: rgba(0, 0, 0, 0.75);
  /* background-color:   turquoise; */
  background-color: #68D4C5;
  font-size: calc(8px + 2.3vmin);
  margin: 0;
  padding: 0;
}
h1, h2, h3, h4 {
  color: rgba(2, 110, 95, 0.95);
}
h1.title {
    margin-top: 0;
    padding-top: 0.25em;
    margin-bottom: 0;
    padding-bottom: 0;
}
h1.subtitle {
    color: rgba(10, 0, 0, 0.75);
    margin-top: 0.1em;
    padding-top: 0;
    margin-bottom: 0;
    padding-bottom: 0;
}
h1.subtitle > span {
    font-size: 0.55em;
    display: block;
}
ul {
    list-style: square;
    margin-top: 0.5em;
    margin-bottom: 0.5em;
}
span.block {
  display: block;
}
.emphasize {
  /* font-size: 1.1em; */
}
a {
  text-decoration: underline;
}
